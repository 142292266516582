import React from 'react'
import Layout from '../../components/Layout/Layout'
import { Link } from 'react-router-dom'
import Banner from "../../Assets/img/banner.jpg";
import './Gsuite.css'

const Gsuite = () => {
  return (
    <Layout>

<div className="gsuite-container" style={{ backgroundImage: `url(${Banner})` }}>
        <div className="gsuite-banner">
          <h1>SiteWorx Gsuit</h1>
          <p>Gsuite Website Best Prices</p>
          <Link to="/">
            <button>Buy Now </button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Gsuite